import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Modal from '../../components/Modal';
import { PencilAltIcon, SearchIcon, XIcon } from '@heroicons/react/solid';
import Loader from '@appmaker/components/Loading';

function CheckBox({ item = {},
	onSelect = () => { },
	currentValue=[],
	value = []
}) {
	const [checked, setChecked] = useState(value.includes(item.id));
	
	const onChange = (checked)=>{
		if(checked){
			setChecked(true);
			onSelect(currentValue?.concat([item]))
		}else{
			setChecked(false);
			onSelect(currentValue?.filter(citem=> citem.id !== item.id))
		}
	}
	useEffect(() => {
		setChecked(value.includes(item.id));
	}, [value, item.id]);

	return (
		<div className="relative flex items-start p-4">
			<div className="flex items-center flex-1">
				<input
					id={item.id}
					name={item.name}
					onChange={(event) => {
						onChange(event.target.checked)
					}}
					type="checkbox"
					checked={checked}
					className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded cursor-pointer"
				/>
				<label
					htmlFor={item.id}
					className="ml-3 text-sm font-medium text-gray-700 flex items-center space-x-2 flex-1 cursor-pointer"
				>
					<img
						src={item?.image?.url}
						alt="banner"
						className="h-10 w-10 border border-gray-50 rounded-sm object-contain overflow-hidden"
					/>
					<p>{item.name ?? item.label}</p>
				</label>
			</div>
		</div>
	);
}

function Radio({
	item = {},
	name = 'radio',
	onSelect = () => { },
	value = [],
}) {
	const [checked, setChecked] = useState(value.includes(item.id));
	const handleRadioChange = () => {
		setChecked(true);
		onSelect(item);
	};

	// Update the checked state when the value prop changes
	useEffect(() => {
		setChecked(value.includes(item.id));
	}, [value, item.id]);

	return (
		<div className="relative flex items-start p-4">
			<div className="flex items-center flex-1">
				<input
					id={item.id}
					name={name}
					type="radio"
					className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 cursor-pointer"
					onChange={handleRadioChange}
					checked={checked}
				/>
				<label
					htmlFor={item.id}
					className="ml-3 text-sm font-medium text-gray-700 flex items-center space-x-2 flex-1 cursor-pointer"
				>
					<img
						src={item?.image?.url}
						alt="banner"
						className="h-10 w-10 border border-gray-50 rounded-sm object-contain overflow-hidden"
					/>
					<p>{item.name ?? item.label}</p>
				</label>
			</div>
		</div>
	);
}

const MultiSelect = ({
	label,
	inner,
	selectionType = 'checkbox',
	options = [],
	onSearch = () => { },
	loading = false,
	onChange = () => { },
	value = []
}) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selected, setSelected] = useState([]);
	const [currentSelection, setCurrentSelection] = useState([]);
	const [query, setQuery] = useState('')

	const itemsToDisplay = selected.length > 4 ? selected.slice(0, 4) : selected;

	const remainingItemsCount = selected.length - itemsToDisplay.length;
	useEffect(() => {
		onSearch(query)
	}, [query])
	useEffect(() => {
		if (isModalOpen === false) {
			onChange(value)
		}
	}, [isModalOpen])
	useEffect(() => {
		if (value?.length !== 0 && selected?.length === 0) {
			setSelected(options?.filter(item => value.includes(item?.id)))
		}
	}, [value, options, selected])
	return (
		<div className={clsx('w-full', !inner && 'mt-4')}>
			<p className="font-regular text-sm mb-1 block">{label}</p>
			{selected.length > 0 ? (
				<div className="flex gap-2 flex-wrap border border-dashed border-gray-300 p-1 rounded-md">
					<div className="grid grid-cols-2 gap-2 w-full">
						{itemsToDisplay.map((item) => (
							<div
								key={item.id}
								className="flex items-center space-x-2 bg-gray-100 rounded-full px-2 py-1 w-full"
							>
								<img
									src={item?.image?.url}
									alt="banner"
									className="h-6 w-6 flex-shrink-0 border border-gray-50 rounded-full object-contain overflow-hidden"
								/>
								<p className="text-sm truncate" title={item.name ?? item.label}>
									{item.name ?? item.label}
								</p>
							</div>
						))}
					</div>
					{remainingItemsCount > 0 && (
						<div className="flex items-center space-x-2 bg-gray-100 rounded-full px-2 py-1">
							<p className="text-sm">+{remainingItemsCount} more</p>
						</div>
					)}
					<button
						type="button"
						className="inline-flex items-center space-x-1 rounded-md text-white bg-indigo-600 px-4 py-1 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
						onClick={() => {
							setIsModalOpen(true);
							setSelected([]);
						}}
					>
						<PencilAltIcon className="h-4 w-4" aria-hidden="true" />
						<span>Edit</span>
					</button>
				</div>
			) : (
				<button
					type="button"
					className="inline-flex rounded-md text-white bg-indigo-600 px-2 py-1 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
					onClick={() => setIsModalOpen(true)}
				>
					Select Collection
				</button>
			)}
			<Modal open={isModalOpen} onClose={() => { }} size="xl">
				<div className="w-full flex justify-between bg-gray-50 py-3 px-4">
					<p className="text-lg font-medium">Collections</p>
					<button onClick={() => setIsModalOpen(() => {
						setQuery('');
						return false
					})}>
						<XIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
					</button>
				</div>
				<div className="relative mx-4 my-2 rounded-md">
					<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
						<SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
					</div>
					<input
						type="search"
						name="search"
						id="search"
						className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
						placeholder="Search"
						onChange={(event) => setQuery(event.target.value)}

					/>
				</div>
				<div
					className="border-t border-b border-gray-100 overflow-y-auto divide-gray-100 divide-y"
					style={{
						minHeight: '24rem',
						maxHeight: 'calc(100vh - 15rem)',
					}}
				>
					{loading && (
						<div className="relative flex items-start p-4">
							<Loader />
						</div>
					)}
					{options?.map((item) => {
						if (selectionType === 'checkbox') {
							return <CheckBox
								key={item.id}
								item={item}
								onSelect={(value) => {
									setCurrentSelection(value)
								}}
								currentValue={currentSelection}
								value={value}
							/>;
						}
						return <Radio
							value={value}
							key={item.id}
							item={item}
							onSelect={(value) => {
								setCurrentSelection([value])
							}}
						/>;
					})}
				</div>
				<div className="flex items-center justify-end px-4 py-2 space-x-2">
					<button
						type="button"
						className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
						onClick={() => setIsModalOpen(() => {
							setQuery('');
							return false
						})}
					>
						Cancel
					</button>
					<button
						type="button"
						className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
						disabled={loading}
						onClick={() => {
							setSelected(currentSelection)
							onChange([...new Set(currentSelection?.map(item => item?.id))])
							setIsModalOpen(() => {
								setQuery('');
								return false
							})
						}}
					>
						Add
					</button>
				</div>
			</Modal>
		</div>
	);
};

export default MultiSelect;
