import React from 'react';
/* This example requires Tailwind CSS v2.0+ */
import { useProject } from '@appmaker/core/hooks';
import { SwitchItem } from './components/SwitchItem';
import TextField from './components/TextField';
import { PromiseSelect } from './components/PromiseSelect';
import { SimpleSelect } from './components/SimpleSelect';
import Heading from './components/Heading';
import StatusStep from './components/StatusStep';
import FileUpload from './fields/FileUpload';
import FieldGroup from './fields/FieldGroup';
import TemplateInput from './fields/TemplateInput';
import { fields } from './fields';
import JsonTextField from './components/JsonTextField';
import InfiniteText from './fields/InfiniteText';
import InfiniteForm from './fields/InfiniteForm';
import CustomProductVariant from './fields/CustomProductVariant/index';
import DateTime from './fields/DateTime';
import Collections from './fields/collections';
export function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}
const defaultFormItems = {
	...fields,
	string: TextField,
	json: JsonTextField,
	Input: TextField,
	FileUpload: FileUpload,
	'promise-select': PromiseSelect,
	select: SimpleSelect,
	switch: SwitchItem,
	FieldGroup,
	LaunchScreen: FieldGroup,
	Heading,
	StatusStep,
	TemplateInput,
	'infinite-text': InfiniteText,
	'infinite-form': InfiniteForm,
	'shopify-custom-product-variant': CustomProductVariant,
	'date-time': DateTime,
	'collections': Collections
};

function useDepenendices() {
	const project = useProject();
	return { projectId: project.projectId };
}
const FormItem = ({
	store,
	name,
	onFileUpload,
	properties,
	onChange = () => {},
	defaultValue,
	_internalValue,
	onDeleteFile,
}) => {
	const { type, uiType, getOptions = () => {}, Component } = properties;
	const deps = useDepenendices();
	const handler = {
		apply(target, thisArg, args) {
			return target(args[0], deps);
		},
	};
	const getOptionsProxy = new Proxy(getOptions, handler);
	const fieldProps = {
		name,
		onFileUpload,
		onDeleteFile,
		properties,
		onChange,
		defaultValue,
		promiseOptions: getOptionsProxy,
		_internalValue,
		store,
		FormItem,
	};
	if (type === 'component') {
		return <Component {...fieldProps} />;
	}
	const ComponentField = defaultFormItems[uiType || type];
	if (ComponentField) {
		return <ComponentField {...fieldProps} />;
	}
	return <div className="hidden">{`"No Item"=>${uiType || type}`}</div>;
};

export default FormItem;
