import MultiSelect from "../MultiSelect";
import { collection, collectionGQL } from '@appmaker/shopify/api';
import debounce from 'lodash/debounce';
import { useEffect, useState, useCallback } from "react";

const Collections = (props = {}) => {
    // gid://shopify/Collection/466029084990  gid://shopify/Collection/453695144254
    const { properties = {}, onChange, defaultValue } = props;
    const {
        isMulti = false
    } = properties
    const [options, setOptions] = useState([]);
    const [isLoading, setLoading] = useState(false);

    const getCollection = async (query, ids) => {
        try {
            const response = await collectionGQL({ search: query, ids });
            if (response) {
                return Promise.resolve(response)
            }
            return Promise.resolve([]);
        } catch (error) {
            return Promise.resolve([])
        }

    }
    const searchProduct = useCallback(debounce(async (query) => {
        const response = await getCollection(query && query.length !== 0 ? query : undefined);
        setOptions(response);
        setLoading(false)
    }, 500), [])
    const onSearch = (value) => {
        setLoading(true)
        searchProduct(value)
    }
    useEffect(() => {
        (async () => {
            setLoading(true)
            const currentOptions = await getCollection()
            if (currentOptions
                && defaultValue
                && defaultValue?.length > 0
                && !currentOptions?.find(item => defaultValue?.includes(item.id))
            ) {
                const collectionIdData = await getCollection(undefined, defaultValue);
                setOptions(currentOptions.concat(collectionIdData))
            } else {
                setOptions(currentOptions)
            }

            setLoading(false)
        })()
    }, [])
    useEffect(() => {
        // only passing the id, so if the id not present in initial array
        (async () => {
            if (options
                && defaultValue
                && defaultValue?.length > 0
                && !options?.find(item => defaultValue?.includes(item.id))
            ) {
                const collectionIdData = await getCollection(undefined, defaultValue);
                setOptions(options.concat(collectionIdData))
            }
        })()
    }, [defaultValue, options])
    return <MultiSelect
        {...properties}
        value={defaultValue}
        loading={isLoading}
        onSearch={(value) => { onSearch(value) }}
        options={options}
        selectionType={isMulti ? 'checkbox' : 'radio'}
        onChange={(value) => {
            onChange(value)
        }}
    />
}


export default Collections