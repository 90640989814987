import axios from 'axios';
import { getProjectId } from '@appmaker/core/store/project';
import { BASE_URL, DASHBOARD_GRAPHQL_URL } from '@appmaker/core/api/core';


export const collectionTemplate = async ({ search } = {}) => {
    // const projectId = getProjectId()
    const url = `https://www.babyjalebi.com/collections.json`;
    try {
        const option = {
            url,
        };
        const response = await axios(option);
        return response.data.collections;
    } catch (error) {
        return Promise.reject(new Error('Error when loading upload media'));
    }
};

export const tags = async ({ search } = {}) => {
    const projectId = getProjectId()
    const url = `${BASE_URL}/projects/${projectId}/api-proxy/tags?${search ? `search=${search}` : ''}`;
    try {
        const option = {
            url,
            withCredentials: true,
            credentials: 'include',
        };
        const response = await axios(option);
        return response.data.body;
    } catch (error) {
        return Promise.reject(new Error('Error when loading upload media'));
    }
};
export const collection = async ({ search } = {}) => {
    const projectId = getProjectId()
    const url = `${BASE_URL}/projects/${projectId}/api-proxy/collections?${search ? `search=${search}` : ''}`;
    try {
        const option = {
            url,
            withCredentials: true,
            credentials: 'include',
        };
        const response = await axios(option);
        return response.data.body;
    } catch (error) {
        return Promise.reject(new Error('Error when loading upload media'));
    }
};
export const products = async ({ search } = {}) => {
    const projectId = getProjectId()
    const url = `${BASE_URL}/projects/${projectId}/api-proxy/products?${search ? `search=${search}` : ''}`;
    try {
        const option = {
            url,
            withCredentials: true,
            credentials: 'include',
        };
        const response = await axios(option);
        return response.data.body;
    } catch (error) {
        return Promise.reject(new Error('Error when loading upload media'));
    }
};
export const prebuildProductScroller = async ({ search } = {}) => {
    const projectId = getProjectId()
    const url = `${BASE_URL}/projects/${projectId}/api-proxy/prebuildProductScroller?${search ? `search=${search}` : ''}`;
    try {
        const option = {
            url,
            withCredentials: true,
            credentials: 'include',
        };
        const response = await axios(option);
        return response.data.body;
    } catch (error) {
        return Promise.reject(new Error('Error when loading upload media'));
    }
};


export const blogs = async ({ search } = {}) => {
    const projectId = getProjectId()
    const url = `${BASE_URL}/projects/${projectId}/api-proxy/blogs?${search ? `search=${search}` : ''}`;
    try {
        const option = {
            url,
            withCredentials: true,
            credentials: 'include',
        };
        const response = await axios(option);
        return response.data.body;
    } catch (error) {
        return Promise.reject(new Error('Error when loading upload media'));
    }
};

export const pages = async ({ search } = {}) => {
    const projectId = getProjectId()
    const url = `${BASE_URL}/projects/${projectId}/api-proxy/pages?${search ? `search=${search}` : ''}`;
    try {
        const option = {
            url,
            withCredentials: true,
            credentials: 'include',
        };
        const response = await axios(option);
        return response.data.body;
    } catch (error) {
        return Promise.reject(new Error('Error when loading upload media'));
    }
};

export const productWithVariant = async ({ search, variant } = {}) => {
    const projectId = getProjectId()
    let url = `${BASE_URL}/projects/${projectId}/api-proxy/productWithVariant?${search ? `search=${search}` : ''}`;
    if (search && variant) {
        url += `&variant=${variant}`
    }
    if (variant && !search) {
        url += `variant=${variant}`
    }
    try {
        const option = {
            url,
            withCredentials: true,
            credentials: 'include',
        };
        const response = await axios(option);
        return response.data.body;
    } catch (error) {
        return Promise.reject(new Error('Error when loading upload media'));
    }
}


export const productWithId = async ({ productId }) => {
    const projectId = getProjectId();
    let url = `${BASE_URL}/projects/${projectId}/api-proxy/singleProduct?productId=${productId}`;
    try {
        const option = {
            url,
            withCredentials: true,
            credentials: 'include',
        };
        const response = await axios(option);
        return response.data.body;
    } catch (error) {
        return Promise.reject(new Error('Error when loading'));
    }
}


export const collectionGQL = async ({ search, ids }) => {
    const query = `
        query ($search: String, $ids: [String]) {
            collections(search: $search, ids: $ids) {
            nodes {
                id
                handle
                label: title
                image {
                url
                }
            }
            }
        }
    `
    const projectId = getProjectId();
    const url = `${DASHBOARD_GRAPHQL_URL}/${projectId}/`
    const response = await axios.post(
        url,
        JSON.stringify({
            query,
            variables: {
                search,
                ids
            }
        }),
        {
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            withCredentials: true,
        }
    );
    return response?.data?.data?.collections?.nodes
}
